import React, { useState } from "react";
import { withIronSessionSsr } from "iron-session/next";
import {
  PasswordInput,
  TextInput,
  Button,
  Group,
  Box,
  Container,
  Alert,
  Anchor,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useForm } from "@mantine/form";
import { Logo } from "../components/Logo/Logo";
import { sessionOptions } from "../lib/session";
import { MainLayout } from "../components/Layouts/MainLayout";
import Head from "next/head";
import { getSiteUrl } from "../lib/utils/getSiteUrl";

const Login = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const signInHandler = async (values) => {
    setSubmitting(true);
    const response = await fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values }),
    });
    const result = await response.json();

    if (result.ok) {
      window.location.href = "/me";
    } else if (result.error) {
      setSubmitting(false);
      setLoginError(true);
    }
  };

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#.])[A-Za-z\d@$!%*?&^#.]{8,}$/.test(
          value
        )
          ? null
          : "Invalid Password",
    },
  });
  return (
    <MainLayout user={false}>
      <Head>
        <title>
          Log in to your Moosiqs account and manage your record collection
        </title>
        <meta
          name="description"
          content="Log in and mnanage your record collection and share with friends"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="alternate" href={`${getSiteUrl()}/login`} hrefLang="en" />
      </Head>
      <Container size="sm" px="sm">
        <Logo />
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {loginError && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Login Failed!"
              color="red"
            >
              Incorrect email / password combination!
            </Alert>
          )}
          <form onSubmit={form.onSubmit(signInHandler)}>
            <TextInput
              id="email"
              mt="md"
              label="Email"
              placeholder="your@email.com"
              onInput={() => setLoginError(false)}
              {...form.getInputProps("email")}
            />

            <PasswordInput
              id="password"
              mt="md"
              label="Password"
              placeholder="password"
              onInput={() => setLoginError(false)}
              {...form.getInputProps("password")}
            />

            <Group position="left" mt="md">
              <Anchor href="/forgotten-password">Forgotten Password?</Anchor>
            </Group>

            <Group position="right" mt="md">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Signing in...." : "Sign In"}
              </Button>
            </Group>
          </form>
        </Box>
      </Container>
    </MainLayout>
  );
};

export const getServerSideProps = withIronSessionSsr(async ({ req }) => {
  if (req.session?.user) {
    return {
      redirect: {
        destination: "/me",
        permanent: false,
      },
    };
  }
  return { props: {} };
}, sessionOptions);

export default Login;
